import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Error404PageComponent } from './core';

const routes: Routes = [
  // STORE
  {
    path: '',
    loadChildren: () => import('./main/home/main-home.module').then(m => m.MainHomeModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule)
  },
  // MISC
  {
    path: 'contact',
    loadChildren:  () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'about',
    loadChildren:  () => import('./misc/about/misc-about.module').then(m => m.MiscAboutModule)
  },
  {
    path: 'jobs',
    loadChildren:  () => import('./jobs/jobs.module').then(m => m.JobsModule)
  },
  {
    path: 'legal',
    loadChildren:  () => import('./legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: '404',
    component: Error404PageComponent
  },
  {
    path: '**',
    component: Error404PageComponent
  }
];

@NgModule({
  // Only call RouterModule.forRoot() in the root AppRoutingModule (or the AppModule if that's where you register
  // top level application routes). In any other module, you must call the RouterModule.forChild method to register additional routes.
  imports: [
    RouterModule.forRoot(routes, {
    // If you want to preload all lazy routes when the app loads, uncomment the following line
    // preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    // This value is required for server-side rendering to work.
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
