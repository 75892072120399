<nav class="main-navbar navbar navbar-expand-lg" [ngClass]="{'not-home': !router.isActive('/', true), 'expanded': !navbarCollapsed}" >
  <button (click)="navbarCollapsed = !navbarCollapsed" class="navbar-toggler" type="button" [attr.aria-expanded]="!navbarCollapsed" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      <span *ngIf="navbarCollapsed" class="fa fa-bars"></span>
      <span *ngIf="!navbarCollapsed" class="fa fa-times"></span>
    </span>
  </button>

  <div class="navbar-items-wrapper navbar-collapse collapse" role="navigation" [collapse]="navbarCollapsed">
    <ul class="navbar-items-list navbar-nav">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/']">
          HOME
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/services']">
          UNSERE LEISTUNGEN
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/contact']">
          KONTAKT
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/jobs']">
          STELLENANGEBOTE
        </a>
      </li>
    </ul>
  </div>
</nav>
