<footer class="main-footer">
  <div class="container">
    <div class="row">
      <div class="footer-sitemap-section col-4 col-lg-3">
        <h4 class="sitemap-title">INFO</h4>
        <ul class="sitemap-items-list list-unstyled">
          <li class="sitemap-item">
            <a class="sitemap-link" tabindex="-1" [routerLink]="['/about']">
              Über uns
            </a>
          </li>
          <li class="sitemap-item">
            <a class="sitemap-link" tabindex="-1" [routerLink]="['/legal']">
              Impressum
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
