import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-navbar',
  styleUrls: ['./styles/top-navbar.styles.scss'],
  templateUrl: './top-navbar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TopNavbarComponent {
  navbarCollapsed = true;

  constructor(public router: Router) {}
}
